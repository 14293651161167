import { motion } from "framer-motion"
import styled from "styled-components/macro"

import { Icon } from "../../../../../components"

export const FilesContainer = styled(motion.div)`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  border-top: 2px solid ${({ theme }) => theme.colors.grey300};
  
  ${({ theme }) => theme.medias.portrait} {
    height: auto;
  }
  `

export const Files = styled.div`
  width: 100%;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  `

export const File = styled(motion.a)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  padding: 1.2rem;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey300};
  transition: all .25s ease-in-out;
  text-decoration: none;
  
  > div {
    gap: .5rem;
    display: flex;
    flex-direction: column;
  }
  
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.grey200};
  }
  `

export const FileTitle = styled(motion.p)`
  font-size: ${({ theme }) => theme.typography.fontSizeText};
  text-transform: uppercase;
  color: #1a1a1a;
  font-weight: 700;
  `

export const FileType = styled(motion.p)`
  font-size: ${({ theme }) => theme.typography.fontSizeTextMedium};
  color: #1a1a1a;
  font-weight: 400;
  `

export const FilesTitle = styled(motion.p)`
  font-size: ${({ theme }) => theme.typography.fontSizeTitle};
  color: #1a1a1a;
  font-weight: 700;
  margin-bottom: 3rem;
`

export const FileIcon = styled(Icon)`
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primaryMedium};
  color: white;
`
