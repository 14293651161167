import { AppStyles } from "App.styles"
import { Routes } from "features/navigation"
import moment from "moment"
import { Providers } from "Providers"
import React from "react"
import { BrowserRouter as Router } from "react-router-dom"

const App = () => {
  moment.locale("fr")
  return (
    <Router>
      <Providers>
        <AppStyles/>
        <Routes/>
      </Providers>
    </Router>
  )
}

export default App
