import { AnimatePresence, Variants } from "framer-motion"

import * as Styles from "./AutodiagnosticQuestionView.styles"
import { Autodiagnostic, AutodiagnosticQuestion } from "../../../../../graphql/models/generated"
import { Component } from "../../../../../utilities/types"
import { useSensidiagContext } from "../../../../autodiagnostic/SensidiagProvider"

export const AutodiagnosticQuestionView: Component<AutodiagnosticQuestionProps> =  ({ question, autodiagnostic }) => {
  const {
    answerQuestion,
  } = useSensidiagContext()

  const containerVariants: Variants = {
    enter: {
      opacity: 1,
      transition: {
        staggerChildren: 0.06,
        when: "beforeChildren",
      },
      x: 0,
    },
    exit: { opacity: 0, x: -50 },
    initial: { opacity: 0, x: 50 },
  }

  const childVariants: Variants = {
    enter: {
      opacity: 1,
      x: 0,
    },
    exit: {
      opacity: 0,
      x: 0,
    },
    initial: {
      opacity: 0,
      x: 20,
    },
  }

  return (
    <Styles.ViewContainer>
      <Styles.CounterContainer>
        {autodiagnostic.autodiagnosticQuestions?.map((p, index) => {
          return (
            <Styles.CounterItem key={index} $active={question.id === p.id}/>
          )
        })}
      </Styles.CounterContainer>
      <Styles.QuestionTitle
        variants={containerVariants}
        key={question.id}
        initial="initial"
        animate="enter"
        exit="exit"
      >
        {question.title}
      </Styles.QuestionTitle>
      <AnimatePresence >
        <Styles.OptionsContainer
          variants={containerVariants}
          key={question.id}
          initial="initial"
          animate="enter"
          exit="exit"
          transition={{ duration: 0.2 }}
        >
          {
            question.autodiagnosticQuestionOptions?.map((option, index) => {
              return (
                <Styles.Option
                  transition={{ duration: 0.1 }}
                  variants={childVariants}
                  key={index + option.label}
                  onClick={() => answerQuestion(question.id, option.id)}
                >
                  <Styles.OptionCheckbox>{index + 1}</Styles.OptionCheckbox>
                  <Styles.OptionText>
                    {option.label}
                  </Styles.OptionText>
                </Styles.Option>
              )
            })
          }
        </Styles.OptionsContainer>
      </AnimatePresence>
    </Styles.ViewContainer>
  )
}

export interface AutodiagnosticQuestionProps {
  question: AutodiagnosticQuestion,
  autodiagnostic: Autodiagnostic,
}
