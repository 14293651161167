import { PageLayout } from "features/navigation"
import moment from "moment"
import React from "react"
import { useParams } from "react-router-dom"
import { Component } from "utilities/types"

import { AutodiagnosticList } from "./AutodiagnosticList/AutodiagnosticList"
import { AutodiagnosticView } from "./AutodiagnosticView/AutodiagnosticView"
import * as Styles from "./HomePage.styles"
import { Welcome } from "./Welcome/Welcome"
import { useSensidiagContext } from "../../autodiagnostic/SensidiagProvider"

export const HomePage: Component = () => {
  moment.locale()

  const [ shouldSkipWelcomeMessage, setShouldSkipWelcomeMessage ] = React.useState<
    boolean |
    string |
    null
  >(shouldSkipWelcomeFromLocalStorage)

  const { slug, moduleId } = useParams<{ slug: string, moduleId: string }>()

  const {
    module: moduleData,
    useGetCompany,
  } = useSensidiagContext()

  useGetCompany(slug, parseInt(moduleId))

  return (
    <>
      {moduleData && (
        <PageLayout
          companyLogo={moduleData?.company.companyLogo}
          secondaryLogo={moduleData?.moduleLogo}
        >
          {shouldSkipWelcomeMessage ? (
            <Styles.SensidiagContainer $hideSidebar={moduleData?.autodiagnostics?.length === 1}>
              <AutodiagnosticList/>
              <AutodiagnosticView/>
            </Styles.SensidiagContainer>
          ) : (
            <Welcome module={moduleData} onSkipMessage={() => {
              localStorage.setItem("skipWelcomeMessage", "true")
              setShouldSkipWelcomeMessage(true)
            }}/>
          )}
        </PageLayout>
      )}
    </>
  )

  function shouldSkipWelcomeFromLocalStorage(): string | boolean | null {
    return localStorage.getItem("skipWelcomeMessage")
  }
}
