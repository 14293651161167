/* tslint:disable */ /* eslint-disable */
import * as Types from '../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UpdateSessionMutationVariables = Types.Exact<{
  input: Types.UpdateSessionInput;
}>;


export type UpdateSessionMutation = { updateSession?: { id: number, nanoId: string, autodiagnosticId: number, score?: number | undefined, userRegistered: boolean } | undefined };


export const UpdateSessionDocument = gql`
    mutation UpdateSession($input: UpdateSessionInput!) {
  updateSession(input: $input) {
    id
    nanoId
    autodiagnosticId
    score
    userRegistered
  }
}
    `;
export type UpdateSessionMutationResult = Apollo.MutationResult<UpdateSessionMutation>;
export type UpdateSessionMutationOptions = Apollo.BaseMutationOptions<UpdateSessionMutation, UpdateSessionMutationVariables>;