export abstract class APP_CONFIG {
  /** Backend endpoint */
  static get applicationHostname(): string {
    if (!process.env.REACT_APP_APPLICATION_HOSTNAME) {
      throw new Error("Missing configuration: key 'REACT_APP_APPLICATION_HOSTNAME' is missing in environment variables")
    }

    return process.env.REACT_APP_APPLICATION_HOSTNAME
  }

  /** Backend endpoint */
  static get backendGraphql(): string {
    if (!process.env.REACT_APP_BACKEND_GQL) {
      throw new Error("Missing configuration: key 'REACT_APP_BACKEND_GQL' is missing in environment variables")
    }

    return process.env.REACT_APP_BACKEND_GQL
  }

  /** Backend authentication path */
  static get backendAuthPath(): string {
    if (!process.env.REACT_APP_BACKEND_AUTH_PATH) {
      throw new Error("Missing configuration: key 'REACT_APP_BACKEND_AUTH_PATH' is missing in environment variables")
    }

    return process.env.REACT_APP_BACKEND_AUTH_PATH
  }

  /** Backend raw endpoint (pathless) */
  static get backendHost(): string {
    if (!process.env.REACT_APP_BACKEND_HOST) {
      throw new Error("Missing configuration: key 'REACT_APP_BACKEND_HOST' is missing in environment variables")
    }

    return process.env.REACT_APP_BACKEND_HOST
  }
}
