/* tslint:disable */ /* eslint-disable */
import * as Types from '../models/generated/index';

import { gql } from '@apollo/client';
export type AutodiagnosticFragmentFragment = { availabilityStart: string, availabilityStop: string, name: string, id: number, contestParticipationText?: string | undefined, autodiagnosticQuestions?: Array<{ title: string, id: number, autodiagnosticQuestionOptions?: Array<{ id: number, label: string, value: number }> | undefined }> | undefined, autodiagnosticFiles?: Array<{ id: number, name: string, type: string, documentUrl: string, downloadCount: number }> | undefined, autodiagnosticProfils?: Array<{ id: number, title: string, imageUrl: string, description: string, minScore: number }> | undefined };

export const AutodiagnosticFragmentFragmentDoc = gql`
    fragment AutodiagnosticFragment on Autodiagnostic {
  availabilityStart
  availabilityStop
  name
  id
  contestParticipationText
  autodiagnosticQuestions {
    title
    id
    autodiagnosticQuestionOptions {
      id
      label
      value
    }
  }
  autodiagnosticFiles {
    id
    name
    type
    documentUrl
    downloadCount
  }
  autodiagnosticProfils {
    id
    title
    imageUrl
    description
    minScore
  }
}
    `;