import { replaceColor } from "lottie-colorify"
import { ChangeEvent, useState } from "react" // Step 1: Import useState
import React from "react"
import Lottie, { Options } from "react-lottie"

import * as Styles from "./Welcome.styles"
import WelcomeAnimation from "../../../../assets/lotties/onboarding.json"
import { AutodiagnosticModule } from "../../../../graphql/models/generated"
import { Component } from "../../../../utilities/types"

export const Welcome: Component<WelcomeProps> = ({ module, onSkipMessage }) => {
  const [ isSettlementChecked, setIsSettlementChecked ] = useState(false) // Step 2: State for the checkbox

  const lottieOptions: Options = {
    animationData: replaceColor(
      [ 212, 13, 13 ],
      module.company.color ?? "#000000",
      WelcomeAnimation,
    ),
    autoplay: true,
    loop: true,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  const handleSettlementCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsSettlementChecked(event.target.checked)
  }

  return (
    <Styles.WelcomeContainer>
      <div>
        <Styles.LottieContainer>
          <Lottie options={lottieOptions} />
        </Styles.LottieContainer>
        <Styles.Title dangerouslySetInnerHTML={{ __html: module.primaryText }} />
      </div>
      <Styles.ButtonContainer>
        {/* Step 4: Render the checkbox */}
        {module.contestFeature && (
          <Styles.SettlementCheckbox>
            <input
              type="checkbox"
              aria-checked={isSettlementChecked}
              checked={isSettlementChecked}
              onChange={handleSettlementCheckboxChange}
            />
            <span>
            En cochant cette case, j'accepte le
              <Styles.SettlementLink href={module.settlement} target={"_blank"} rel="noreferrer">
              règlement
              </Styles.SettlementLink>
            du jeu concours
            </span>
          </Styles.SettlementCheckbox>
        )}

        <Styles.Button onClick={onSkipMessage} isDisabled={!isSettlementChecked && module.contestFeature}>
          {/* Disable the button if the checkbox is not checked */}
          C'est parti !
        </Styles.Button>
        {!module.contestFeature && (
          <Styles.DataText>
            Bienvenue sur le Sensi Diag, l’outil d’autodiagnostic 100% anonyme du Groupe JLO. Nous tenons à vous
            informer que nous ne collectons et ne communiquons aucune donnée qui permettrait de vous identifier
            personnellement : cet outil a été conçu pour fournir des analyses et informations utiles,
            sans compromettre votre vie privée.
          </Styles.DataText>
        )}
      </Styles.ButtonContainer>
    </Styles.WelcomeContainer>
  )
}

export interface WelcomeProps {
  module: AutodiagnosticModule,
  onSkipMessage: () => void,
}
