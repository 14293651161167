import { Component } from "utilities/types"

import * as Styles from "./Header.styles"

export const Header: Component<HeaderProps> = ({ companyLogo, cncLogo }) => {
  return (
    <Styles.HeaderContainer
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ delay: 0.2 }}
    >
      <Styles.Header>
        <Styles.LogoContainer>
          <img src={companyLogo} alt={"Company Logo"}/>
        </Styles.LogoContainer>
        <Styles.TitleContainer>
          <Styles.StyledTitle>Sensi'Diag</Styles.StyledTitle>
        </Styles.TitleContainer>
        {cncLogo && (
          <Styles.LogoContainer>
            <img alt={"clap'n'click Logo"} src={cncLogo}/>
          </Styles.LogoContainer>
        )}
      </Styles.Header>
    </Styles.HeaderContainer>
  )
}

export interface HeaderProps {
  companyLogo: string,
  cncLogo: string,
}
