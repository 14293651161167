import { BackendProvider } from "features/backend/BackendProvider"
import { MuiClapAndClickFoThemeProvider, SensidiagThemeProvider } from "features/theme"
import React from "react"

import { SensidiagProvider } from "./features/autodiagnostic/SensidiagProvider"

export const Providers: React.FC = ({ children }) => {
  return (
    <BackendProvider>
      <SensidiagThemeProvider>
        <SensidiagProvider>
          <MuiClapAndClickFoThemeProvider>
            {children}
          </MuiClapAndClickFoThemeProvider>
        </SensidiagProvider>
      </SensidiagThemeProvider>
    </BackendProvider>
  )
}
