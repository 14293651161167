/* tslint:disable */ /* eslint-disable */
import * as Types from '../../models/generated/index';

import { gql } from '@apollo/client';
import { AutodiagnosticFragmentFragmentDoc } from '../../generated/AutodiagnosticFragment';
import * as Apollo from '@apollo/client';
export type GetAutodiagnosticModuleByIdQueryVariables = Types.Exact<{
  slug: Types.Scalars['String'];
  moduleId: Types.Scalars['Int'];
}>;


export type GetAutodiagnosticModuleByIdQuery = { getAutodiagnosticModuleById?: { id: number, moduleLogo: string, secondaryText?: string | undefined, primaryText: string, contestFeature: boolean, availabilityStart: string, availabilityStop: string, settlement: string, customFields?: Array<{ required: boolean, fieldType: string, value: string, label: string, id: number }> | undefined, company: { id: number, updatedAt: string, createdAt: string, color: string, slug: string, companyLogo: string, name: string }, autodiagnostics?: Array<{ availabilityStart: string, availabilityStop: string, name: string, id: number, contestParticipationText?: string | undefined, autodiagnosticQuestions?: Array<{ title: string, id: number, autodiagnosticQuestionOptions?: Array<{ id: number, label: string, value: number }> | undefined }> | undefined, autodiagnosticFiles?: Array<{ id: number, name: string, type: string, documentUrl: string, downloadCount: number }> | undefined, autodiagnosticProfils?: Array<{ id: number, title: string, imageUrl: string, description: string, minScore: number }> | undefined }> | undefined } | undefined };


export const GetAutodiagnosticModuleByIdDocument = gql`
    query GetAutodiagnosticModuleById($slug: String!, $moduleId: Int!) {
  getAutodiagnosticModuleById(slug: $slug, moduleId: $moduleId) {
    id
    customFields {
      required
      fieldType
      value
      label
      id
    }
    company {
      id
      updatedAt
      createdAt
      color
      slug
      companyLogo
      name
    }
    moduleLogo
    secondaryText
    primaryText
    contestFeature
    availabilityStart
    availabilityStop
    settlement
    autodiagnostics {
      ...AutodiagnosticFragment
    }
  }
}
    ${AutodiagnosticFragmentFragmentDoc}`;
export type GetAutodiagnosticModuleByIdQueryResult = Apollo.QueryResult<GetAutodiagnosticModuleByIdQuery, GetAutodiagnosticModuleByIdQueryVariables>;