import React from "react"

import { AutodiagnosticQuestionView } from "./AutodiagnosticQuestion/AutodiagnosticQuestionView"
import { AutodiagnosticResultView } from "./AutodiagnosticResultView/AutodiagnosticResultView"
import * as Styles from "./AutodiagnosticView.styles"
import { EmptyState } from "./EmptyState/EmptyState"
import { Component } from "../../../../utilities/types"
import { useSensidiagContext } from "../../../autodiagnostic/SensidiagProvider"

export const AutodiagnosticView: Component =  () => {
  const {
    currentQuestion,
    currentAutodiagnostic,
    module,
    startAutodiagnosticSession,
  } = useSensidiagContext()

  React.useEffect(() => {
    if (module?.autodiagnostics && module?.autodiagnostics?.length === 1) {
      startAutodiagnosticSession(module?.autodiagnostics[0].id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ module ])

  const component = () : JSX.Element => {
    if (currentQuestion === "result") {
      return (<AutodiagnosticResultView/>)
    } else if (currentQuestion && currentAutodiagnostic) {
      return (<AutodiagnosticQuestionView question={currentQuestion} autodiagnostic={currentAutodiagnostic}/>)
    } else {
      return (<EmptyState/>)
    }
  }

  return (
    <Styles.ViewContainer>
      {
        component()
      }
    </Styles.ViewContainer>
  )
}
