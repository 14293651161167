import { sensidiagFoTheme } from "features/theme/SensidiagFoTheme"

export const muiClapAndClickFoVariables = {
  palette: {
    error: {
      main: sensidiagFoTheme.colors.danger,
    },
    primary: {
      light: sensidiagFoTheme.colors.primaryLight,
      main: sensidiagFoTheme.colors.primary,
    },
    secondary: {
      main: sensidiagFoTheme.colors.secondary,
    },
    tertiary: {
      light: sensidiagFoTheme.colors.green,
      primary: sensidiagFoTheme.colors.success,
    },
    text: {
      disabled: sensidiagFoTheme.colors.grey300,
      light: sensidiagFoTheme.colors.grey200,
      primary: sensidiagFoTheme.colors.black,
    },
  },
  sizes: {
    borderRadius: sensidiagFoTheme.sizes.borderRadius,
    borderRadiusRounded: sensidiagFoTheme.sizes.borderRadiusRounded,
  },
  typography: {
    fontFamily: sensidiagFoTheme.typography.textFontFamily,
    fontSize: {
      caption: sensidiagFoTheme.typography.fontSizeTextExtraSmall,
      text: {
        small: sensidiagFoTheme.typography.fontSizeTextSmall,
      },
      title: {
        dialog: sensidiagFoTheme.typography.fontSizeTitle,
      },
    },
  },
}
