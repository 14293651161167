import { motion } from "framer-motion"
import styled, { css } from "styled-components/macro"

export const HeaderContainer = styled(motion.div)`
  ${({ theme }) => css`
    width: 100%;
    background: ${theme.colors.white};
    box-shadow: ${theme.shadows.button};
    position: relative;
    z-index: 10;
    display: flex;
    flex: 0 1 auto;
  `}
`

export const Header = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  gap: 25px;

  ${({ theme }) => theme.medias.portrait} {
    flex-wrap: wrap;
    padding: 20px;
    gap: 1.5rem;
    justify-content: space-between;
  }
`

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 160px;

  img {
    max-width: 100%;
    height: 55px;
    width: 100%;
    object-fit: contain;

    ${({ theme }) => theme.medias.portrait} {
      height: 40px;

    }
  }
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  
  h2 {
    margin: 0;

    ${({ theme }) => theme.medias.portrait} {
      text-align: center;
      font-size: 1.6rem !important;
    }
  }
`

export const StyledTitle = styled.h2`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  color: #1a1a1a;
`
