import { createTheme } from "@material-ui/core/styles"

import { muiClapAndClickFoVariables } from "./muiClapAndClickFoVariables"
import { sensidiagFoTheme } from "../SensidiagFoTheme"

export interface MuiClapAndClickFoBreakpointOverrides {
  // Disabled (but might be required by some components of MUI (dialogs))
  xs: false,
  sm: false,
  md: false,
  lg: false,
  xl: false,
  portrait: true,
  reducedLandscape: true,
  landscape: true,
}

export enum zIndexMuiTheme {
  modal = 1300
}

export const muiClapAndClickFoTheme = createTheme({
  breakpoints: {
    values: {
      // eslint-disable-next-line sort-keys
      landscape: sensidiagFoTheme.sizes.landscapeWidth,
      portrait: sensidiagFoTheme.sizes.mobileWidth,
      reducedLandscape: sensidiagFoTheme.sizes.portraitWidth,
    },
  },
  palette: muiClapAndClickFoVariables.palette,
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  typography: {
    htmlFontSize: 10,
  },
})
