import { AnimatePresence } from "framer-motion"
import React from "react"

import { AutodiagnosticFiles } from "./AutodiagnosticFiles/AutodiagnosticFiles"
import * as Styles from "./AutodiagnosticResultView.styles"
import { ContestModal } from "./ContestModal/ContestModal"
import { Component } from "../../../../../utilities/types"
import { useSensidiagContext } from "../../../../autodiagnostic/SensidiagProvider"

export const AutodiagnosticResultView: Component =  () => {
  const [ isContestModelOpen, setIsContestModelOpen ] = React.useState(false)

  const {
    endAutodiagnosticSession,
    currentProfil,
    startAutodiagnosticSession,
    module,
  } = useSensidiagContext()

  React.useEffect(() => {
    endAutodiagnosticSession()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!currentProfil) {return null}

  return (
    <Styles.ViewContainer>
      <Styles.ContentContainer>
        <Styles.Content
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 50 }}
        >
          <Styles.ProfilTitle>
            {currentProfil?.title}
          </Styles.ProfilTitle>
          <Styles.Text dangerouslySetInnerHTML={{ __html: currentProfil?.description }}/>
          {module?.contestFeature && (
            <Styles.ContestButton
              onClick={() => setIsContestModelOpen(true)}
            >Participer au concours
            </Styles.ContestButton>
          )}
        </Styles.Content>
        <Styles.Profil src={currentProfil?.imageUrl}/>
      </Styles.ContentContainer>
      <AutodiagnosticFiles/>
      <Styles.Button onClick={() => startAutodiagnosticSession(-1)}>
        Terminer
      </Styles.Button>
      <AnimatePresence>
        {isContestModelOpen && (
          <ContestModal onClose={() => {
            startAutodiagnosticSession(-1)
            setIsContestModelOpen(false)
          }}/>
        )}
      </AnimatePresence>
    </Styles.ViewContainer>
  )
}

