import { useMutation, useQuery } from "@apollo/client"
import { GraphQLError } from "graphql"
import { isEmpty } from "lodash"
import moment from "moment"
import { darken, lighten } from "polished"
import React, { createContext, useContext, useMemo, useState } from "react"
import { useHistory } from "react-router"

import {
  Autodiagnostic, AutodiagnosticModule,
  AutodiagnosticProfil,
  AutodiagnosticQuestion,
} from "../../graphql/models/generated"
import { InitSessionDocument, InitSessionMutation } from "../../graphql/mutations/generated/InitSession"
import { UpdateSessionDocument, UpdateSessionMutation } from "../../graphql/mutations/generated/UpdateSession"
import {
  GetAutodiagnosticModuleByIdDocument,
  GetAutodiagnosticModuleByIdQuery,
} from "../../graphql/queries/generated/GetAutodiagnosticModuleById"
import { ThemeContext } from "../theme"

export type SessionInput = {
  nanoId: string,
  autodiagnosticId: number,
  autodiagnosticAnswers: AutodiagnosticAnswerInput[],
  alreadyRegistered?: boolean,
  score?: number,
  alreadyPlayed?: boolean,
}

type AutodiagnosticAnswerInput = {
  questionId: number,
  optionId: number,
}

type AutodiagnosticContextType = {
  module: AutodiagnosticModule | null,
  currentAutodiagnosticSession: SessionInput | null,
  currentAutodiagnostic: Autodiagnostic | null,

  currentProfil: AutodiagnosticProfil | null,
  startAutodiagnosticSession: (autodiagnosticId: number) => void,

  endAutodiagnosticSession: () => void,

  useGetCompany: (slug: string, moduleId: number) => void,
  answerQuestion: (questionId: number, selectedOptionId: number) => void,
  markAutodiagnosticSessionAsDone: () => void,
  currentQuestion: AutodiagnosticQuestion | null | "result",
  deleteAutodiagnosticSession: () => void,
};

const AutodiagnosticContext = createContext<AutodiagnosticContextType | null>(null)

export const useSensidiagContext = () => {
  const context = useContext(AutodiagnosticContext)
  if (!context) {
    throw new Error("useSensidiagContext must be used within a SensidiagProvider")
  }
  return context
}

type AutodiagnosticProviderProps = {
  children: React.ReactNode,
};

export const SensidiagProvider = ({ children }: AutodiagnosticProviderProps) => {
  const { setTheme, theme } = useContext(ThemeContext)
  const history = useHistory()

  const [ module, setModule ] = useState<AutodiagnosticModule | null>(null)
  const [ currentAutodiagnosticSession, setCurrentAutodiagnosticSession ] = useState<SessionInput | null>(null)
  const [ currentQuestionIndex, setCurrentQuestionIndex ] = useState<number>(-1)
  const [ currentAutodiagnostic, setCurrentAutodiagnostic ] = useState<Autodiagnostic | null>(null)
  const [ currentProfil, setCurrentProfil ] = useState<AutodiagnosticProfil | null>(null)

  const [ startSession ] = useMutation<InitSessionMutation>(InitSessionDocument)
  const [ updateSession ] = useMutation<UpdateSessionMutation>(UpdateSessionDocument)

  React.useEffect(
    () => {
      if (module?.company.color) {
        setTheme({
          ...theme,
          colors: {
            ...theme.colors,
            primary: module?.company.color,
            primaryDark: darken(0.1, module?.company.color),
            primaryLight: lighten(0.25, module?.company.color),
            primaryMedium: lighten(0.1, module?.company.color),
          },
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ module ],
  )

  const useGetCompany = (slug: string, moduleId: number) => {
    useQuery<GetAutodiagnosticModuleByIdQuery>(GetAutodiagnosticModuleByIdDocument, {
      onCompleted(data) {
        if (!data.getAutodiagnosticModuleById || isEmpty(data?.getAutodiagnosticModuleById.autodiagnostics)) {
          history.replace("/")
        } else {
          const start = moment(data?.getAutodiagnosticModuleById?.availabilityStart)
          const stop = moment(data?.getAutodiagnosticModuleById?.availabilityStop)
          const isAvailable = moment().isAfter(start) && moment().isBefore(stop)
          if (!isAvailable) {
            history.replace("/")
          } else {
            setModule(data?.getAutodiagnosticModuleById)
          }
        }
      },
      variables: {
        moduleId: moduleId,
        slug: slug,
      },
    })
  }

  const startAutodiagnosticSession = (autodiagnosticId: number) => {
    const autodiagnostic = module?.autodiagnostics?.find((autodiagnostic) => autodiagnostic.id === autodiagnosticId)
    if (!autodiagnostic) {
      setCurrentAutodiagnosticSession(null)
      setCurrentAutodiagnostic(null)
      setCurrentProfil(null)
      setCurrentQuestionIndex(-1)
    }
    setCurrentProfil(null)
    autodiagnostic && initSession(autodiagnosticId).then((result) => {
      if (result?.nanoId) {
        setCurrentAutodiagnosticSession(result)
        setCurrentAutodiagnostic(autodiagnostic)
        setCurrentQuestionIndex(0)
      }
    }).catch((error) => {
      console.error(error)
    })
  }

  async function initSession(autodiagnosticId: number) {
    const session = localStorage.getItem(`autodiagnosticSession-${autodiagnosticId}`)

    if (session) {
      return JSON.parse(session)
    } else {
      return await startSession({
        variables: {
          autodiagnosticId,
        },
      }).then((session) => {
        const formattedSession = {
          autodiagnosticAnswers: [],
          autodiagnosticId: autodiagnosticId,
          nanoId: session?.data?.initSession?.nanoId,
        }
        if (session?.data?.initSession?.nanoId) {
          localStorage.setItem(`autodiagnosticSession-${autodiagnosticId}`, JSON.stringify(formattedSession))
        }
        return formattedSession
      })
    }
  }

  const answerQuestion = (questionId: number, selectedOptionId: number) => {
    if (!currentAutodiagnosticSession) {
      throw new Error("No active autodiagnostic session")
    }

    const autodiagnosticFromSession = module?.autodiagnostics?.find(
      (q) => q.id === currentAutodiagnosticSession.autodiagnosticId,
    )

    const questionIndex = autodiagnosticFromSession?.autodiagnosticQuestions?.map(
      (question) => question.id,
    )?.indexOf(questionId) as number

    const selectedOptionIds = [ ...currentAutodiagnosticSession.autodiagnosticAnswers ]

    selectedOptionIds[questionIndex] = {
      optionId: selectedOptionId,
      questionId: questionId,
    }
    setCurrentAutodiagnosticSession({
      ...currentAutodiagnosticSession,
      autodiagnosticAnswers: selectedOptionIds,
    })
    localStorage.setItem(`autodiagnosticSession-${currentAutodiagnostic?.id}`, JSON.stringify(
      { ...currentAutodiagnosticSession, autodiagnosticAnswers: selectedOptionIds },
    ))
    setCurrentQuestionIndex(questionIndex + 1)
  }

  const endAutodiagnosticSession = async () => {
    if (!currentAutodiagnosticSession) {
      throw new Error("No active autodiagnostic session")
    }

    if (currentAutodiagnosticSession.alreadyPlayed) {
      // fake update session to display new profil, but do not send data to backoffice
      return await retrySession()
    } else {
      return await updateSession({
        variables: {
          input: {
            autodiagnosticAnswers: currentAutodiagnosticSession.autodiagnosticAnswers,
            nanoId: currentAutodiagnosticSession.nanoId,
          },
        },
      }).then((result) => {
        const score = result?.data?.updateSession?.score
        const formattedSession = {
          alreadyPlayed: true,
          alreadyRegistered:result?.data?.updateSession?.userRegistered ?? false,
          autodiagnosticAnswers: currentAutodiagnosticSession.autodiagnosticAnswers,
          autodiagnosticId: currentAutodiagnosticSession.autodiagnosticId,
          nanoId: currentAutodiagnosticSession.nanoId,
          score: score,
        }

        const profils = (currentAutodiagnostic?.autodiagnosticProfils &&
          // eslint-disable-next-line no-unsafe-optional-chaining
          [ ...currentAutodiagnostic?.autodiagnosticProfils ]) ?? []

        const profil = score !== undefined && (score !== null || true) && !isEmpty(profils) &&
          profils.sort(
            (a, b) => b.minScore - a.minScore,
          )?.find((profil) => profil.minScore <= score)

        if (profil) {
          setCurrentAutodiagnosticSession(formattedSession)
          setCurrentProfil(profil)
        }

        localStorage.setItem(
          `autodiagnosticSession-${currentAutodiagnosticSession.autodiagnosticId}`, JSON.stringify(formattedSession),
        )
        return formattedSession
      }).catch((error) => {
        if ((error as GraphQLError).message === "Session not found") {
          deleteAutodiagnosticSession()
        }
      })
    }
  }

  const retrySession = async () => {
    if (!currentAutodiagnosticSession) {
      throw new Error("No active autodiagnostic session")
    }

    const autodiagnosticAnswers = currentAutodiagnosticSession.autodiagnosticAnswers
    const autodiagnosticQuestions = currentAutodiagnostic?.autodiagnosticQuestions

    if (!autodiagnosticQuestions) {
      throw new Error("No autodiagnostic questions available")
    }

    // Initialize the score to 0
    let score = 0

    // Iterate through questions and answers to calculate the score
    autodiagnosticQuestions.forEach((question) => {
      const selectedAnswerId =
        autodiagnosticAnswers.find((answer) => question.id === answer.questionId)?.optionId

      if (selectedAnswerId) {
        // Find the selected answer option
        const selectedOption = (question.autodiagnosticQuestionOptions ?? []).find(
          (option) => option.id === selectedAnswerId,
        )

        if (selectedOption) {
          // Add the value of the selected option to the score
          score += selectedOption.value || 0
        }
      }
    })

    // Create the formatted session with the updated score
    const formattedSession = {
      ...currentAutodiagnosticSession,
      alreadyPlayed: true,
      score: score,
    }

    // You can also update the current profile based on the new score here if needed
    const profils = (currentAutodiagnostic?.autodiagnosticProfils &&
      // eslint-disable-next-line no-unsafe-optional-chaining
      [ ...currentAutodiagnostic?.autodiagnosticProfils ]) ?? []

    const profil = score !== undefined && (score !== null || true) && !isEmpty(profils) &&
      profils.sort(
        (a, b) => b.minScore - a.minScore,
      )?.find((profil) => profil.minScore <= score)

    if (profil) {
      setCurrentAutodiagnosticSession(formattedSession)
      setCurrentProfil(profil)
    }

    // Store the updated session in localStorage
    localStorage.setItem(
      `autodiagnosticSession-${currentAutodiagnosticSession.autodiagnosticId}`,
      JSON.stringify(formattedSession),
    )

    return formattedSession
  }

  function markAutodiagnosticSessionAsDone() {
    if (!currentAutodiagnosticSession) {
      throw new Error("No active autodiagnostic session")
    }

    const formattedSession = {
      alreadyPlayed: true,
      alreadyRegistered: true,
      autodiagnosticAnswers: currentAutodiagnosticSession.autodiagnosticAnswers,
      autodiagnosticId: currentAutodiagnosticSession.autodiagnosticId,
      nanoId: currentAutodiagnosticSession.nanoId,
      score: currentAutodiagnosticSession.score,
    }

    localStorage.setItem(`autodiagnosticSession-${currentAutodiagnostic?.id}`, JSON.stringify(formattedSession))
    
    setCurrentAutodiagnosticSession(formattedSession)
  }

  function getAutodiagnosticQuestionForIndex(index: number) {
    if (!currentAutodiagnosticSession) {
      return null
    }
    const autodiagnostic = module?.autodiagnostics?.find(
      (q) => q.id === currentAutodiagnosticSession.autodiagnosticId,
    )

    if (currentQuestionIndex > 0 && !autodiagnostic?.autodiagnosticQuestions?.[index]) {
      return "result"
    }
    return (autodiagnostic && autodiagnostic.autodiagnosticQuestions?.[index]) || null
  }

  function deleteAutodiagnosticSession() {
    if (!currentAutodiagnosticSession) {
      throw new Error("No active autodiagnostic session")
    }
    localStorage.removeItem(`autodiagnosticSession-${currentAutodiagnostic?.id}`)
    setCurrentAutodiagnosticSession(null)
    setCurrentAutodiagnostic(null)
    setCurrentProfil(null)
    setCurrentQuestionIndex(-1)
  }

  const value: AutodiagnosticContextType = useMemo(
    () => ({
      answerQuestion,
      currentAutodiagnostic,
      currentAutodiagnosticSession,
      currentProfil,
      currentQuestion: getAutodiagnosticQuestionForIndex(currentQuestionIndex),
      deleteAutodiagnosticSession,
      endAutodiagnosticSession,
      markAutodiagnosticSessionAsDone,
      module: module,
      startAutodiagnosticSession,
      useGetCompany,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      answerQuestion,
      module,
      currentAutodiagnostic,
      currentAutodiagnosticSession,
      currentProfil,
      currentQuestionIndex,
      endAutodiagnosticSession,
      startAutodiagnosticSession,
      useGetCompany,
      markAutodiagnosticSessionAsDone,
      deleteAutodiagnosticSession,
    ],
  )

  return (
    <AutodiagnosticContext.Provider value={value}>
      {children}
    </AutodiagnosticContext.Provider>
  )
}
