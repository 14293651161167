import { useMutation } from "@apollo/client"
import React from "react"

import * as Styles from "./AutodiagnosticFiles.styles"
import { DownloadFileEventDocument, DownloadFileEventMutation } from "../../../../../../graphql/mutations/generated/DownloadFileEvent"
import { Component } from "../../../../../../utilities/types"
import { useSensidiagContext } from "../../../../../autodiagnostic/SensidiagProvider"

export const AutodiagnosticFiles: Component =  () => {
  const {
    currentAutodiagnostic,
  } = useSensidiagContext()

  const [ downloadFile ] = useMutation<DownloadFileEventMutation>(DownloadFileEventDocument)

  return (
    <Styles.FilesContainer
      initial={{ opacity: 0, x: -50 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 50 }}
    >
      <Styles.FilesTitle>
        Ressources à télécharger
      </Styles.FilesTitle>
      <Styles.Files>
        {currentAutodiagnostic?.autodiagnosticFiles?.map((file, index) => (
          <Styles.File
            href={file.documentUrl}
            target={"_blank"}
            key={index}
            onClick={() => downloadFileHandler(file.id)}
          >
            <div>
              <Styles.FileIcon name={"description"}/>
            </div>
            <div>
              <Styles.FileTitle>
                {file.name}
              </Styles.FileTitle>
              <Styles.FileType>
                {file.type === "correction" ? "Correction" : "Ressource additionnelle"}
              </Styles.FileType>
            </div>
          </Styles.File>
        ))}
      </Styles.Files>

    </Styles.FilesContainer>
  )

  function downloadFileHandler(fileId: number) {
    downloadFile({
      variables: {
        fileId,
      },
    })
  }
}

