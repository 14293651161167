import styled from "styled-components/macro"

import { Icon } from "../../../../components"

export const ViewContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  ${({ theme }) => theme.medias.portrait} {
    padding: 2rem;
    text-align: center;
  }
  `

export const EmptyTitle = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSizeTitle};
  color: ${({ theme }) => theme.colors.grey500};
  margin-bottom: 1rem;
`

export const EmptyText = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSizeText};
  color: ${({ theme }) => theme.colors.grey700};
  `

export const EmptyIcon = styled(Icon)`
  font-size: 2.6rem;
  color: ${({ theme }) => theme.colors.grey100};
  padding: 1.6rem;
  background-color: ${({ theme }) => theme.colors.primaryMedium};
  box-shadow: 0 0 0 10px ${({ theme }) => theme.colors.primaryLight};
  margin-bottom: 5rem;
  border-radius: ${({ theme }) => theme.sizes.borderRadiusRounded};
`

