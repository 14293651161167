import { motion } from "framer-motion"
import styled from "styled-components/macro"

export const ViewContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 5rem 2rem;
  `

export const QuestionTitle = styled(motion.p)`
  font-size: ${({ theme }) => theme.typography.fontSizeTitleBig};
  color: #1a1a1a;
  max-width: 650px;
  text-align: left;
  margin: 2.3rem;
  font-weight: 700;
`

export const CounterContainer = styled.div`
  display: flex;
  justify-content: center;
  `

export const CounterItem = styled.div<CounterItemProps>`
  width: 2rem;
  height: 1rem;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.grey300};
  margin: 0 0.4rem;
  transition: all .25s ease-in-out;
  
  ${({ $active, theme }) =>
    $active &&
    `
    width: 3.2rem;
    background-color: ${theme.colors.primary};
  `}
`

export const OptionsContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  width: 100%;
  max-width: 700px;
  padding: 4rem 2rem;
  
  ${({ theme }) => theme.medias.portrait} {
    padding: 4rem 0;
  }
  `

export const OptionCheckbox = styled.div`
  min-width: 3rem;
  min-height: 3rem;
  border-radius: 10000px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2rem;
  background-color: ${({ theme }) => theme.colors.primary};
  transition: all .25s ease-in-out;
  `

export const Option = styled(motion.button)`
  display: flex;
  align-items: center;
  gap: 2rem;
  border: none;
  padding: 2rem;
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  background-color: ${({ theme }) => theme.colors.white};
  transition: all .25s ease-in-out;
  cursor: pointer;
  box-shadow: ${({ theme }) => theme.shadows.card};

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryLight};
    
    p {
      color: white;
    }
    
    ${OptionCheckbox} {
      
    }
  }
  `

export const OptionText = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSizeText};
  color: ${({ theme }) => theme.colors.black};
  transition: all .25s ease-in-out;
  text-align: left;
  `

export interface CounterItemProps {
  $active?: boolean,
}
