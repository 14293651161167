/* tslint:disable */ /* eslint-disable */
import * as Types from '../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type AddAutodiagnosticContestUserParticipationMutationVariables = Types.Exact<{
  input: Types.AutodiagnosticUserContestInput;
}>;


export type AddAutodiagnosticContestUserParticipationMutation = { addAutodiagnosticContestUserParticipation: boolean };


export const AddAutodiagnosticContestUserParticipationDocument = gql`
    mutation AddAutodiagnosticContestUserParticipation($input: AutodiagnosticUserContestInput!) {
  addAutodiagnosticContestUserParticipation(input: $input)
}
    `;
export type AddAutodiagnosticContestUserParticipationMutationResult = Apollo.MutationResult<AddAutodiagnosticContestUserParticipationMutation>;
export type AddAutodiagnosticContestUserParticipationMutationOptions = Apollo.BaseMutationOptions<AddAutodiagnosticContestUserParticipationMutation, AddAutodiagnosticContestUserParticipationMutationVariables>;