/* eslint-disable sort-keys */
import { t } from "ttag"

const business = {
  "errors": {
    "participationAlreadyExists": "Vous avez déjà participé à cet autodiagnostic",
  },
  "languages": {
    "defaultTerritory": {
      "en": "en_US",
      "fr": "fr_FR",
    },
  },
  "validationPatterns": {
    // eslint-disable-next-line max-len, no-useless-escape
    "email": new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
    // List of supported special characters : @$!%*-?&.()+,:<=>^_{}~/ #"'`,;\|[]
    // eslint-disable-next-line max-len, no-useless-escape
    "password": new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%.*\-?&()\+,:<=>^_{}~\/ #"'`,;\\|[\]])[A-Za-z\d@$!%*\-?&\.()\+,:<=>^_{}~\/ #"'`,;\\|[\]]{8,}$/),
  },
  "validationRules": {
    "password": {
      "capital": {
        "message": () => t`Minimum 1 capital`,
        "value": 1,
      },
      "minLength": {
        "message": () => t`Minimum 8 characters`,
        "value": 8,
      },
      "number": {
        "message": () => t`Minimum 1 number`,
        "value": 1,
      },
      "specialChar": {
        "message": () => t`Minimum 1 special character`,
        "value": 1,
      },
    },
  },
}

export default business
