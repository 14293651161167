
import { HomePage } from "features/home"
import { NotFoundPage } from "features/notfound"
import { Route, Switch } from "react-router-dom"

export const Routes = () => (
  <Switch>
    {/*Features pages */}
    <Route component={HomePage} path="/:slug/:moduleId" exact/>

    {/*Errors pages */}
    <Route component={NotFoundPage}/>
  </Switch>
)
