/* tslint:disable */ /* eslint-disable */
import * as Types from '../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type InitSessionMutationVariables = Types.Exact<{
  autodiagnosticId: Types.Scalars['Int'];
}>;


export type InitSessionMutation = { initSession: { id: number, nanoId: string, autodiagnosticId: number } };


export const InitSessionDocument = gql`
    mutation InitSession($autodiagnosticId: Int!) {
  initSession(autodiagnosticId: $autodiagnosticId) {
    id
    nanoId
    autodiagnosticId
  }
}
    `;
export type InitSessionMutationResult = Apollo.MutationResult<InitSessionMutation>;
export type InitSessionMutationOptions = Apollo.BaseMutationOptions<InitSessionMutation, InitSessionMutationVariables>;