import backgroundDecorator from "assets/images/backgroundDecorator.svg"
import { Header } from "features/components"
import React from "react"
import { Component } from "utilities/types"

import * as Styles from "./PageLayout.styles"

export const PageLayout: Component<PageLayoutProps> = ({ companyLogo, secondaryLogo, children })  => {
  return (
    <Styles.Container>
      <Styles.BackgroundDecorator src={backgroundDecorator} position={"tl"}/>
      <Styles.BackgroundDecorator src={backgroundDecorator} position={"br"}/>
      <Styles.ContentContainer
        initial={{ opacity: 0, y: 150 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -150 }}
      >
        <Header companyLogo={companyLogo} cncLogo={secondaryLogo}/>
        <Styles.Content>
          {children}
        </Styles.Content>
      </Styles.ContentContainer>
    </Styles.Container>
  )
}

export interface PageLayoutProps {
  companyLogo: string,
  secondaryLogo: string,
}
