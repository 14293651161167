import { SensidiagFoTheme, sensidiagFoTheme } from "features/theme/SensidiagFoTheme"
import React, { useState } from "react"
import { ThemeProvider } from "styled-components"

import { Component } from "../../utilities/types"

interface ThemeContextType {
  setTheme: (theme: SensidiagFoTheme) => void,
  theme: SensidiagFoTheme,
}

export const ThemeContext = React.createContext<ThemeContextType>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTheme: () => {},
  theme: sensidiagFoTheme,
})

export const SensidiagThemeProvider: Component = ({ children }) => {
  const [ theme, setTheme ] = useState(sensidiagFoTheme)

  return (
    <ThemeContext.Provider value={{ setTheme, theme }}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}
