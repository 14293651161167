import { motion } from "framer-motion"
import styled from "styled-components/macro"

import { Icon } from "../../../../components"

export const Card = styled(motion.button)<CardProps>`
  background-color: white;
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  padding: 16px;
  border: none;
  box-shadow: ${({ theme }) => theme.shadows.card};
  transition: box-shadow 0.2s ease-in-out;
  display: grid;
  grid-template-columns: 1fr 40px;
  
  ${({ $isActive, theme }) => $isActive && `
    box-shadow: 0 0 0 2px ${theme.colors.primary};
  `
}
  

  
  ${({ status, theme }) => (status === "todo" || status === "done")  && `
    box-shadow: none;
    background-color: ${theme.colors.grey200};
    color: ${theme.colors.grey300};
   
    ${AutodiagnosticTitle} {
      color: ${theme.colors.grey300};
    }
  `}
  
  ${({ status, theme }) => status === "inProgress" && `
    cursor: pointer;      
    
    &:hover {
      box-shadow: 0 0 0 2px ${theme.colors.primary};
  `}
  
`

export const InfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
`

export const StatusContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const Date = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSizeLabelSmall};
  color: ${({ theme }) => theme.colors.grey500};
`

export const AutodiagnosticTitle = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSizeLabelBig};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primaryDark};
  margin-bottom: .6rem;
`

export const EmptyIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.grey100};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6ab04c;
  border-radius: ${({ theme }) => theme.sizes.borderRadiusRounded};
`

export interface CardProps {
  $isActive?: boolean,
  status: "todo" | "inProgress" | "done",
}
