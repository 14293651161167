import { motion } from "framer-motion"
import styled from "styled-components/macro"

import { PrimaryButton } from "../../../../components"
import { SecondaryButton } from "../../../../components/SecondaryButton"

export const ViewContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 5rem 10rem;
  gap: 5rem;
  overflow-y: auto;
  
  ${({ theme }) => theme.medias.portrait} {
    padding: 2rem;
    gap: 2rem;
  }
  `

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 4fr 2fr;
  gap: 6rem;
  width: 100%;

  ${({ theme }) => theme.medias.portrait} {
    grid-template-columns: 1fr;
  }
`

export const Content = styled(motion.div)`
  display: flex;
  flex-direction: column;
`

export const ProfilTitle = styled(motion.p)`
  font-size: ${({ theme }) => theme.typography.fontSizeTitleBig};
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 700;
  margin-bottom: 1.2rem;
`

export const Text = styled(motion.p)`
  font-size: ${({ theme }) => theme.typography.fontSizeText};
  color: #1a1a1a;
  font-weight: 400;
`

export const Profil = styled(motion.img)`
  width: 100%;
  height: auto;
  
  ${({ theme }) => theme.medias.portrait} {
    display: none;
  }
`

export const Button = styled(SecondaryButton)`
  max-width: 200px;
  margin-top: 3rem;
  
  ${({ theme }) => theme.medias.portrait} {
    max-width: 100%;
  }
  `

export const ContestButton = styled(PrimaryButton)`
  max-width: 350px;
  margin-top: 3rem;
  
  ${({ theme }) => theme.medias.portrait} {
    max-width: 100%;
  }
  `
