import { Variants } from "framer-motion"
import moment from "moment"
import React from "react"

import * as Styles from "./AutodiagnosticList.styles"
import { AutodiagnosticListItem } from "./AutodiagnosticListItem/AutodiagnosticListItem"
import { Autodiagnostic } from "../../../../graphql/models/generated"
import { Component } from "../../../../utilities/types"
import { useSensidiagContext } from "../../../autodiagnostic/SensidiagProvider"

type AutodiagnosticByStatus = {
  todo: Autodiagnostic[],
  inProgress: Autodiagnostic[],
  done: Autodiagnostic[],
}

export const AutodiagnosticList: Component =  () => {
  const {
    module: companyData,
  } = useSensidiagContext()

  if (companyData?.autodiagnostics?.length === 1) {
    return null
  }

  const autodiagnosticList = companyData?.autodiagnostics && orderAutodiagnoticsByStatus(companyData?.autodiagnostics)

  if (!autodiagnosticList) {return null}

  const containerVariants: Variants = {
    enter: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        when: "beforeChildren",
      },
      x: 0,
    },
    exit: { opacity: 0, x: -300 },
  }

  const childVariants: Variants = {
    enter: {
      opacity: 1,
      x: 0,
    },
    exit: {
      opacity: 0,
      x: -20,
    },
  }

  return (
    <Styles.ListContainer
      variants={containerVariants}
      initial="exit"
      animate="enter"
      exit="exit"
    >
      { autodiagnosticList.inProgress.length > 0 && (
        <>
          <Styles.StatusTitle variants={childVariants} >Autodiagnostics disponibles</Styles.StatusTitle>
          {autodiagnosticList.inProgress.map((autodiagnostic) => (
            <AutodiagnosticListItem
              key={autodiagnostic.id}
              autodiagnostic={autodiagnostic}
              variants={childVariants}
              status="inProgress"
            />
          ))}
        </>
      )}
      { autodiagnosticList.todo.length > 0 && (
        <>
          <Styles.StatusTitle variants={childVariants} >Bientôt disponible</Styles.StatusTitle>
          {autodiagnosticList.todo.map((autodiagnostic) => (
            <AutodiagnosticListItem
              key={autodiagnostic.id}
              autodiagnostic={autodiagnostic}
              variants={childVariants}
              status="todo"
            />
          ))}
        </>
      )}

      { autodiagnosticList.done.length > 0 && (
        <>
          <Styles.StatusTitle variants={childVariants} >Déjà terminé</Styles.StatusTitle>
          {autodiagnosticList.done.map((autodiagnostic) => (
            <AutodiagnosticListItem
              key={autodiagnostic.id}
              autodiagnostic={autodiagnostic}
              variants={childVariants}
              status="done"
            />
          ))}
        </>
      )}

    </Styles.ListContainer>
  )

  function orderAutodiagnoticsByStatus(autodiagnosticList: Autodiagnostic[]): AutodiagnosticByStatus {
    const autodiagnosticByStatus: AutodiagnosticByStatus = {
      done: [],
      inProgress: [],
      todo: [],
    }

    autodiagnosticList.forEach((autodiagnostic) => {
      const autodiagnosticStatus = getAutodiagnosticStatus(autodiagnostic)
      autodiagnosticByStatus[autodiagnosticStatus].push(autodiagnostic)
    })

    return autodiagnosticByStatus
  }

  function getAutodiagnosticStatus(autodiagnostic: Autodiagnostic): "todo" | "inProgress" | "done" {
    const now = moment()
    const autodiagnosticStartDate =
      moment(autodiagnostic.availabilityStart).format("YYYY-MM-DD HH:mm:ss")
    const autodiagnosticEndDate =
      moment(autodiagnostic.availabilityStop).format("YYYY-MM-DD HH:mm:ss")

    if (now.isBefore(autodiagnosticStartDate)) {
      return "todo"
    } else if (now.isAfter(autodiagnosticEndDate)) {
      return "done"
    } else {
      return "inProgress"
    }
  }
}
