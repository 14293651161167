import { motion } from "framer-motion"
import styled, { css } from "styled-components/macro"

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    // background-color gradient from bottom left to top right, primary to primary medium
    background: linear-gradient(135deg, ${theme.colors.primary} 0%, ${theme.colors.primaryMedium} 100%);
    min-height: 100vh;
    max-width: 100vw;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    padding: 0 3rem;
    
    ${theme.medias.portrait} {
      padding: 0;
    }
  `}
`

export const ContentContainer = styled(motion.div)<{ $withHomeButton?: boolean, $withNavGrid?: boolean  }>`
  ${({ theme }) => css`
    width: 100%;
    z-index: 10;
    max-width: 1460px;
    background: white;
    border-radius: ${theme.sizes.borderRadius};
    box-shadow: ${theme.shadows.header};
    overflow: hidden;
    height: auto;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;

    ${theme.medias.portrait} {
      height: 100vh;
      overflow-y: auto;
    }
  `}
`

export const Content = styled(motion.div)`
  margin: 0 auto;
  height: 100%;
  width: 100%;
  position: relative;
`

export const BackgroundDecorator = styled.img<BackgroundDecoratorProps>`
  position: absolute;
  opacity: 0.4;
  ${({ position }) => {
    switch (position) {
      case "tl":
        return css`
          top: -100px;
          left: -100px;
        `
      case "tr":
        return css`
          top: -100px;
          right: -100px;
        `
      case "bl":
        return css`
          bottom: -100px;
          left: -100px;
        `
      case "br":
        return css`
          bottom: -100px;
          right: -100px;
        `
    }
  }}
`

export interface BackgroundDecoratorProps {
  position: "tl" | "tr" | "bl" | "br",
}
