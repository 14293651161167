import { Variants } from "framer-motion"
import moment from "moment"
import React from "react"

import * as Styles from "./AutodiagnosticListItem.styles"
import { Autodiagnostic } from "../../../../../graphql/models/generated"
import { Component } from "../../../../../utilities/types"
import { SessionInput, useSensidiagContext } from "../../../../autodiagnostic/SensidiagProvider"

export const AutodiagnosticListItem: Component<AutodiagnosticListItemProps> =  (
  { autodiagnostic, status, variants },
) => {
  const {
    startAutodiagnosticSession,
    currentAutodiagnosticSession,
  } = useSensidiagContext()

  const isActive = currentAutodiagnosticSession?.autodiagnosticId === autodiagnostic.id

  const sessionFromStorage: SessionInput =
    JSON.parse(localStorage.getItem(`autodiagnosticSession-${autodiagnostic.id}`) ?? "{}")

  return (
    <Styles.Card
      onClick={() => !isActive && status === "inProgress" && startAutodiagnosticSession(autodiagnostic.id)}
      status={status}
      key={autodiagnostic.id}
      $isActive={isActive}
      variants={variants}
      aria-label={`Accéder à ${autodiagnostic.name}`}
    >
      <Styles.InfosContainer>
        <Styles.AutodiagnosticTitle>{autodiagnostic.name}</Styles.AutodiagnosticTitle>
        <Styles.Date>
          Du {moment(autodiagnostic.availabilityStart).format("DD-MM-YYYY")}<br></br>
          Au {moment(autodiagnostic.availabilityStop).format("DD-MM-YYYY")}
        </Styles.Date>
      </Styles.InfosContainer>
      {(sessionFromStorage.score || (currentAutodiagnosticSession?.score
          && currentAutodiagnosticSession?.autodiagnosticId === autodiagnostic.id)) ? (
          <Styles.StatusContainer>
            <Styles.EmptyIcon name={"check"}/>
          </Styles.StatusContainer>
        ) : <></>}
    </Styles.Card>
  )
}

export interface  AutodiagnosticListItemProps {
  autodiagnostic: Autodiagnostic,
  status: "todo" | "inProgress" | "done",
  variants: Variants,
}
