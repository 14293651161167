import * as Styles from "./EmptyState.styles"
import { Component } from "../../../../../utilities/types"
import { useSensidiagContext } from "../../../../autodiagnostic/SensidiagProvider"

export const EmptyState: Component =  () => {
  const {
    module: companyData,
  } = useSensidiagContext()

  const headingMessage = companyData?.autodiagnostics?.length === 1 ?
    "Merci pour votre participation !" :
    "Aucun autodiagnostic n'est sélectionné"
  const bodyMessage =
    companyData?.autodiagnostics?.length === 1 ?
      "Vous pouvez désormais quitter cette page" :
      "Sélectionnez un autodiagnostic dans la liste à gauche pour commencer"
  return (
    <Styles.ViewContainer>
      <Styles.EmptyIcon name={"list_alt"}/>
      <Styles.EmptyTitle>
        {headingMessage}
      </Styles.EmptyTitle>
      <Styles.EmptyText>
        {bodyMessage}
      </Styles.EmptyText>
    </Styles.ViewContainer>
  )
}
