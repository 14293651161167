import { motion } from "framer-motion"
import styled from "styled-components/macro"

export const ListContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 2rem;
  border-right: 1px solid ${({ theme }) => theme.colors.grey300};
  background: ${({ theme }) => theme.colors.white};
  max-height: 100%;
  overflow-y: auto;
  
  ${({ theme }) => theme.medias.portrait} {
    height: 170px;
    overflow-y: auto;
  }
`

export const StatusTitle = styled(motion.p)`
font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.grey500};
`
