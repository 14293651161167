/* tslint:disable */ /* eslint-disable */
import * as Types from '../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DownloadFileEventMutationVariables = Types.Exact<{
  fileId: Types.Scalars['Int'];
}>;


export type DownloadFileEventMutation = { downloadFileEvent: boolean };


export const DownloadFileEventDocument = gql`
    mutation DownloadFileEvent($fileId: Int!) {
  downloadFileEvent(fileId: $fileId)
}
    `;
export type DownloadFileEventMutationResult = Apollo.MutationResult<DownloadFileEventMutation>;
export type DownloadFileEventMutationOptions = Apollo.BaseMutationOptions<DownloadFileEventMutation, DownloadFileEventMutationVariables>;