import { Component } from "utilities/types"

import * as Styles from "./ThreeDotsLoader.styles"

export const ThreeDotsLoader: Component = () => (
  <Styles.AnimatedContainer>
    <Styles.AnimatedDot/>
    <Styles.AnimatedDot/>
    <Styles.AnimatedDot/>
  </Styles.AnimatedContainer>
)
