import styled, { css } from "styled-components/macro"

import { PrimaryButton } from "../../../components"

export const WelcomeContainer = styled.div`
  ${({ theme }) => css`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
    height: 100%;
    min-height: 70vh;
  justify-content: space-between;
    padding: 3rem;
    gap: 3rem;
    
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    
${theme.medias.portrait} {
  height: 100%;
  min-height: auto;
  padding: 2rem;
}
`
}`

export const LottieContainer = styled.div`
  max-width: 600px;
  width: 100%;
  height:auto;
  `

export const Title = styled.div`
  ${({ theme }) => css`
    text-align: center;
    font-size: ${theme.typography.fontSizeTitle};
    
    p {
      font-size: 1.9rem;
      font-weight: 600;
    }

    ${theme.medias.portrait} {
      font-size: ${theme.typography.fontSizeText};
    }
    `
}`

export const ButtonContainer = styled.div`
  width: 100%;
`
export const Button = styled(PrimaryButton)`
  max-width: 200px;
`

export const DataText = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSizeTextSmall};
  color: ${({ theme }) => theme.colors.grey500};
  margin-top: 5rem;
  max-width: 600px;
  text-align: center;
`

export const SettlementCheckbox = styled.label`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: ${theme.typography.fontSizeText};
    color: ${theme.colors.grey500};
    margin-bottom: 2rem;
    cursor: pointer;
    
    ${theme.medias.portrait} {
      font-size: ${theme.typography.fontSizeTextSmall};
    }
  `}
`

export const SettlementLink = styled.a`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    text-decoration: underline;
    cursor: pointer;
    margin: 0 0.5rem;
  `}
`
