import { useMutation } from "@apollo/client"
import { GraphQLError } from "graphql"
import React from "react"
import { useForm } from "react-hook-form"

import * as Styles from "./ContestModal.styles"
import business from "../../../../../../config/business"
import {
  AddAutodiagnosticContestUserParticipationDocument,
  AddAutodiagnosticContestUserParticipationMutation, AddAutodiagnosticContestUserParticipationMutationVariables,
} from "../../../../../../graphql/mutations/generated/AddAutodiagnosticContestUserParticipation"
import { Component } from "../../../../../../utilities/types"
import { useSensidiagContext } from "../../../../../autodiagnostic/SensidiagProvider"
import { PrimaryButton } from "../../../../../components"
import { SecondaryButton } from "../../../../../components/SecondaryButton"
import { TextInput } from "../../../../../components/TextInput"

type ContestFormValues = {
  firstName: string,
  lastName: string,
  email: string,
  [key: string]: string,
};

export const ContestModal: Component<ContestModalProps> = (props) => {
  const [ error, setError ] = React.useState<string | null>(null)
  const [ isParticipationSubmitted, setIsParticipationSubmitted ] = React.useState(false)

  const { module, currentAutodiagnosticSession, markAutodiagnosticSessionAsDone, currentAutodiagnostic  } = useSensidiagContext()

  const { register, handleSubmit } = useForm<ContestFormValues>()

  const [ addAutodiagnosticParticipationMutation ] = useMutation<AddAutodiagnosticContestUserParticipationMutation>(AddAutodiagnosticContestUserParticipationDocument)

  const onSubmit = async (data: ContestFormValues) => {
    await addAutodiagnosticParticipation(data)
  }

  return (
    <Styles.ModalContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Styles.Modal>
        {isParticipationSubmitted ? (
          <Styles.ModalHeading>
            {error ? (
              <>
                <Styles.ModalTitle size={"extraLarge"}>{error}</Styles.ModalTitle>
                <Styles.StyledIcon color={"#cc2e2e"} name={"error"}/>
                <Styles.ButtonContainer>
                  <SecondaryButton onClick={props.onClose}>Fermer</SecondaryButton>
                </Styles.ButtonContainer>
              </>
            ) : (
              <>
                <Styles.ModalTitle size={"extraLarge"}>{currentAutodiagnostic?.contestParticipationText}</Styles.ModalTitle>
                <Styles.StyledIcon color={"#2ecc71"} name={"check_circle_outline"}/>
                <Styles.ButtonContainer>
                  <PrimaryButton onClick={props.onClose}>Fermer</PrimaryButton>
                </Styles.ButtonContainer>
              </>
            )}
          </Styles.ModalHeading>
        ) : (
          <>
            <Styles.ModalHeading>
              <Styles.ModalTitle size={"extraLarge"}>Participez au concours !</Styles.ModalTitle>
              <p>
                Pour participer au concours, veuillez remplir le formulaire ci-dessous.
              </p>
            </Styles.ModalHeading>
            <Styles.Form onSubmit={handleSubmit(onSubmit)}>
              <Styles.FieldSet>
                <TextInput
                  isRequired label="Prénom" type="text" placeholder="John" {...register("firstName")}
                />
                <TextInput
                  isRequired label="Nom" type="text" placeholder="Doe" {...register("lastName")}
                />
              </Styles.FieldSet>
              <TextInput
                isRequired
                label="email"
                type="text"
                placeholder="johndoe@gmail.com"
                {...register("email", { pattern: /^\S+@\S+$/i })}
              />
              {module?.customFields?.map((customField) => {
                if (customField) {
                  if (customField.fieldType === "text") {
                    return (
                      <TextInput
                        isRequired={customField.required}
                        label={customField.label}
                        key={customField.id}
                        type="text"
                        placeholder=""
                        {...register(`${customField.id}`)} />
                    )
                  }
                  else if (customField.fieldType === "select") {
                    const selectList = JSON.parse(customField.value)
                    return (
                      <Styles.SelectContainer
                        key={customField.id}
                      >
                        <Styles.SelectLabel>
                          {customField.label}
                          {customField.required ? " *" : ""}
                        </Styles.SelectLabel>
                        <Styles.SelectInput
                          {...register(
                            `${customField.id}`, { required: customField.required },
                          )}
                          defaultValue=""
                          placeholder={"Choisissez une option..."}
                        >
                          {selectList.map((option: string) => (
                            <option key={option} value={option}>{option}</option>
                          ))}
                        </Styles.SelectInput>
                      </Styles.SelectContainer>
                    )
                  }
                }
                return null
              })}
              <Styles.ButtonContainer>
                <SecondaryButton onClick={props.onClose}>Annuler</SecondaryButton>
                <PrimaryButton type="submit">Participer</PrimaryButton>
              </Styles.ButtonContainer>
            </Styles.Form>
          </>
        )}
      </Styles.Modal>
    </Styles.ModalContainer>
  )

  async function addAutodiagnosticParticipation(data: ContestFormValues) {
    const { firstName, lastName, email, ...customFields  } = data
    const customFieldsValues = Object.entries(customFields).map(([ customFieldId, customFieldValue ]) => ({
      customFieldId: parseInt(customFieldId),
      value: customFieldValue,
    }))

    const input: AddAutodiagnosticContestUserParticipationMutationVariables = {
      input: {
        autodiagnosticId: currentAutodiagnosticSession?.autodiagnosticId as number,
        customFields: customFieldsValues,
        email: email,
        firstName: firstName,
        fromSessionNanoId: currentAutodiagnosticSession?.nanoId as string,
        lastName: lastName,
        moduleId: module?.id as number,
      },
    }

    try {
      const result = await addAutodiagnosticParticipationMutation({
        variables: input,
      })

      if (result.data?.addAutodiagnosticContestUserParticipation) {
        setIsParticipationSubmitted(true)
        markAutodiagnosticSessionAsDone()
      }
    } catch (e) {
      setIsParticipationSubmitted(true)
      if ((e as GraphQLError).message === business.errors.participationAlreadyExists) {
        markAutodiagnosticSessionAsDone()
      }
      setError((e as GraphQLError).message)
    }
  }
}

export interface ContestModalProps {
  onClose: () => void,
}
