import { motion } from "framer-motion"
import styled, { css } from "styled-components/macro"

import { Icon, Title } from "../../../../../components"

export const ModalContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5rem;
  overflow-y: auto;
`

export const FieldSet = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap:2rem;
`

export const Modal = styled(motion.div)`
  max-width: 600px;
  background: white;
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  box-shadow: ${({ theme }) => theme.shadows.card};
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
  padding: 5rem 2rem;
  justify-content: center;
  `

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;
  `

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
`

export const SelectInput = styled.select`
  ${({ theme }) => css`
    height: 5rem;
    border-radius: ${theme.sizes.borderRadiusButton};
    padding: 1.5rem 2.5rem;
    border: 1px solid ${theme.colors.borderDark};
    background: white;
    
    // Input text
    color: ${theme.colors.grey500};
    font-size: ${theme.typography.fontSizeTextMedium};
    
    &::placeholder{
      color: ${theme.colors.grey500};
    }
    
    &:focus-visible {
      outline: ${theme.colors.primary} auto 1px;
    }
  `}
 
`

export const SelectLabel = styled.label`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeLabel};
    text-transform: uppercase;
  `}
`

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

export const ModalTitle = styled(Title)`
  text-align: center;
  margin: 0;
  `

export const ModalHeading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  `

export const StyledIcon = styled(Icon)<StyledIconProps>`
  display: block;
  font-size: 8rem;
  color: ${({ color }) => color};
  margin-bottom: 2rem;
`

export interface StyledIconProps {
  color: string,
}
