import styled from "styled-components/macro"

export const SensidiagContainer = styled.div<{$hideSidebar: boolean}>` 
  display: grid;
  height: 70vh;
  grid-template-columns: 300px 1fr;
  background-color: ${({ theme }) => theme.colors.grey100};
  
  ${({ theme }) => theme.medias.portrait} {
    grid-template-columns: 1fr;
    grid-template-rows: fit-content(100%) 1fr;
    height: 100%;
  }
  
  ${({ $hideSidebar }) => $hideSidebar && `
    grid-template-columns: 1fr;
    `}
  
`
